// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState, useEffect } from 'react';
import {
    Button, Dropdown, Menu, Modal,
} from 'antd/lib';

import { Row, Col } from 'antd/lib/grid';
import Tag from 'antd/lib/tag';
import { message } from 'antd';
import Select from 'antd/lib/select';
import Text from 'antd/lib/typography/Text';
import { Model } from 'reducers';
import CVATTooltip from 'components/common/cvat-tooltip';
import { getCore } from 'cvat-core-wrapper';

const { Option } = Select;
const core = getCore();

interface Props {
    model: Model;
}

interface Project {
    id: number;
    name: string;
  }

export default function DeployedModelItem(props: Props): JSX.Element {
    const { model } = props;
    const [projectList, setProjectList] = useState<Project[]>([]);

    // Fetch the list of projects from `core` on component mount
    useEffect(() => {
        const fetchProjects = async () => {
        try {
            const projectList = await core.projects.get({});

            // Extract `id` and `name` properties from the project list
            const formattedList = projectList.map((project: Project) => ({
            id: project.id,
            name: project.name,
            }));

            setProjectList(formattedList);
        } catch (error) {
            console.log('Error fetching project list:', error);
        }
        };

        fetchProjects();
    }, []);


    const handleMenuClick = (model: Model, { key }: { key: string }) => {
        let selectedVersion: string = '';
        let selectedProject: number| null = null;

        const handleSelectProject = (value: number | null) => {
            selectedProject = value;
        };

        const handleSelectVersion = (value: string) => {
            selectedVersion = value;
        };

        const handleOk = (menuOption: string) => {
            if (!selectedProject || !selectedVersion) {
                message.error('Please select a project and a version');
                return false;
            }
            const input = {
                "project": selectedProject,
                "version": selectedVersion,
                "event": menuOption
            };
            core.lambda.train(model, input).then(() => {}
            ).catch((error: any) => {
                message.error(`Failed to start training '${model.name}'`);
            });
        };

        Modal.confirm({
            title: 'Please choose your training options:',
            content: (
                <div>
                <Select
                    placeholder="Select project"
                    onChange={handleSelectProject}
                    style={{ width: '100%', marginTop: '1rem' }}
                >
                    {projectList.map((project) => (
                        <Option key={project.id} value={project.id}>
                            {project.name}
                        </Option>
                    ))}
                </Select>
                <Select
                    placeholder='Select version'
                    style={{ width: '100%', marginTop: '1rem' }}
                    onChange={handleSelectVersion}
                    notFoundContent='No Retrainable Versions'
                >
                    {model.versions.map((option, index) => (
                        <Select.Option key={index} value={option}>
                            {option}
                        </Select.Option>
                    ))}
                </Select>
                </div>
            ),
            okText: 'Train',
            cancelText: 'Cancel',
            onOk: () => handleOk(key),
        });
    };

    const menu = (
        <Menu onClick={(event) => handleMenuClick(model, event)}>
            <Menu.Item key='re-learn'>Re-learn</Menu.Item>
            <Menu.Item key='continuously learn'>Continuously learn</Menu.Item>
            <Menu.Item key='transfer learn'>Transfer learn</Menu.Item>
        </Menu>
    );

    return (
        <Row className='cvat-models-list-item'>
            <Col span={3}>
                <Tag color='purple'>{model.framework}</Tag>
            </Col>
            <Col span={3}>
                <CVATTooltip overlay={model.name}>
                    <Text className='cvat-text-color'>{model.name}</Text>
                </CVATTooltip>
            </Col>
            <Col span={3}>
                <Tag color='orange'>{model.type}</Tag>
            </Col>
            <Col span={7}>
                <CVATTooltip overlay={model.description}>
                    <Text style={{ whiteSpace: 'normal', height: 'auto' }}>{model.description}</Text>
                </CVATTooltip>
            </Col>
            <Col span={5}>
                <Select showSearch placeholder='Supported labels' style={{ width: '90%' }} value='Supported labels'>
                    {model.labels.map(
                        (label): JSX.Element => (
                            <Select.Option value={label} key={label}>
                                {label}
                            </Select.Option>
                        ),
                    )}
                </Select>
            </Col>
            <Col span={3}>
                <Dropdown overlay={menu}>
                    <Button>Actions</Button>
                </Dropdown>
            </Col>
        </Row>
    );
}
